@import 'mixins.scss';

#nprogress .spinner {
  z-index: 10000;
  border: 1px solid $border;
  margin-left: rem(-73);
}

#nprogress .spinner-icon {
  border-top-color: $primary;
  border-left-color: $primary;
}
