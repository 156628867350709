@mixin lips($num) {
  @for $i from 1 through $num {
    &:nth-child(n + #{$i + 1}) {
      margin-left: #{$i * 31px};
    }
  }
}

.container {
  height: 100vh;
  background: #ebf2f1;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Helvetica Neue';
  font-size: 16px;
  line-height: 1.5;
  padding-top: 50px;
  h1 {
    font-size: 24px;
    line-height: 40px;
    margin: 0 auto 16px;
    padding: 0 20px;
    letter-spacing: 0.5px;
    font-weight: 600;
    max-width: 600px;
  }
  p {
    font-weight: 300;
    max-width: 600px;
    margin: 0 auto 24px;
    letter-spacing: 0.5px;
    padding: 0 20px;
    a {
      color: #d24848 !important;
      text-decoration: underline !important;
    }
  }
}

.browser {
  width: 400px;
  min-width: 200px;
  min-height: 264px;
  background: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
  border-radius: 3px;
  animation: bobble 1.8s ease-in-out infinite;
  position: relative;
  left: 50%;
  margin-left: -200px;
  .controls {
    width: 100%;
    height: 32px;
    background: #e8ecef;
    border-radius: 3px 3px 0 0;
    box-sizing: border-box;
    padding: 10px 12px;
    i {
      height: 12px;
      width: 12px;
      border-radius: 100%;
      display: block;
      float: left;
      background: #d6dce0;
      margin-right: 8px;
    }
  }
}

.eye {
  position: absolute;
  left: 34%;
  top: 80px;
  width: 32px;
  height: 32px;
  opacity: 1;
  + .eye {
    right: 34%;
    left: auto;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 40px;
    width: 3px;
    border-radius: 2px;
    background-color: #ff5e5b;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.mouth {
  position: absolute;
  width: 250px;
  top: 178px;
  left: 50%;
  margin-left: -125px;
  height: 40px;
  .lips {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 40px;
    width: 3px;
    border-radius: 2px;
    background-color: #ff5e5b;
    transform: rotate(-54deg);
    &:nth-child(odd) {
      transform: rotate(54deg);
    }
    @include lips(8);
  }
}

@keyframes bobble {
  0%,
  100% {
    margin-top: 40px;
    margin-bottom: 48px;
    box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
  }
  50% {
    margin-top: 54px;
    margin-bottom: 34px;
    box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .browser {
    width: 280px;
    margin-left: -140px;
    .eye {
      left: 30%;
      + .eye {
        left: auto;
        right: 30%;
      }
    }
  }
}
