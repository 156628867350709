@import 'mixins.scss';

.ant-typography {
  code {
    color: $menuPrimary;
  }
}

.text {
  &-success {
    color: $success !important;
  }
  &-danger {
    color: $danger !important;
  }
  &-warning {
    color: $warning !important;
  }
}
