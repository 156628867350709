@import 'mixins.scss';

.ant-select-selection-item-content {
  .ant-avatar {
    width: rem(18);
    height: rem(18);
    line-height: rem(18);
    font-size: rem(10);
  }
}
/* select with a single value */
.ant-select-selection-item {
  .ant-avatar {
    width: rem(20);
    height: rem(20);
    line-height: rem(20);
    font-size: rem(11);
  }
}
.ant-avatar {
  margin-left: 0 !important;
  &.ant-avatar-icon {
    svg {
      vertical-align: sub;
    }
  }
}
.avatar-container {
  .ant-avatar {
    @include transition-middle;
    &-removeBtn {
      @include transition-fast;
      visibility: hidden;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1002;
    }
    &:not(.ant-avatar-lg) > .ant-avatar-string {
      transform: scale(1) translateX(-50%) translateY(3%) !important;
    }
  }
  &:hover {
    .ant-avatar {
      box-shadow: inset 0 0 0 2px #fff;
      border-color: transparent !important;
      z-index: 1000;
      &-removeBtn {
        visibility: visible;
      }
    }
  }
}
.ant-avatar-group {
  flex-wrap: wrap;
  margin-left: 8px;
  .avatar-container,
  > .ant-avatar {
    margin-left: -8px !important;
  }
}
.ant-avatar-group-popover {
  .ant-popover-inner-content {
    display: flex;
    flex-wrap: wrap;
    .avatar-container {
      margin: 2px;
    }
  }
}
