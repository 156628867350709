@import 'mixins.scss';

.ant-popover {
  &.noVerticalPadding .ant-popover-inner-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.noTopPadding .ant-popover-inner-content {
    padding-top: 0;
  }
  .ant-list-items {
    .ant-list-item.chosen .ant-list-item-meta-title {
      font-weight: bold;
    }
    .ant-list-item-meta {
      align-items: center !important;
      &-title {
        margin-bottom: 0 !important;
      }
      &-description {
        margin-top: 4px;
      }
    }
  }
  .scrollable {
    max-height: rem(300);
    overflow-y: auto;
    overflow-x: hidden;
  }
  &.small {
    width: rem(200);
    .ant-list-item {
      border-bottom: none;
      padding: rem(6) 1rem;
      .ant-btn,
      .ant-list-item-meta-title {
        line-height: normal;
        height: auto;
        margin-bottom: 0;
      }
      .ant-list-item-meta-avatar {
        margin-right: rem(5);
      }
    }
  }
  &.medium {
    width: rem(450);
  }
  &.noItemBorder {
    .ant-list-item {
      border-bottom: none;
    }
  }
  &.max-medium {
    max-width: rem(400);
  }
  &.scrollable-max-medium .scrollable {
    max-height: rem(600);
  }
}
