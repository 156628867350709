@import 'mixins.scss';

.hoverableButtons {
  .actionButton,
  .actionButton > div {
    opacity: 0;
    margin-right: rem(4);
    @include transition-slow;
    &:last-child {
      margin-right: 0;
    }
  }
  .ant-typography-copy {
    color: inherit !important;
  }
  &:hover,
  &.active {
    .actionButton {
      opacity: 0.5;
      &:hover,
      &.active,
      div:hover,
      div.active {
        opacity: 1;
      }
    }
    &.fullyVisible {
      .actionButton {
        opacity: 1;
      }
    }
  }
}
