@import 'mixins.scss';

.ant-form-item-label > label {
  color: #a1a1c2 !important;
}
.ant-form-item.layout-horizontal {
  flex-direction: row !important;
  align-items: center;
  .ant-form-item-control {
    text-align: right;
  }
}
