@import 'mixins.scss';

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.logoContainer {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 rem(28) 0 0;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: rem(24);
    white-space: nowrap;
    width: 100%;

    svg {
      margin-right: rem(6);
      height: rem(30);
      width: rem(30);
      path:nth-child(1) {
        stroke: $primary;
        fill: $primary;
        stroke-width: 1px;
        animation: logoAnimate 6s ease-in-out infinite;
      }
      path:nth-child(2) {
        stroke: $primary;
        fill: $primary;
        stroke-width: 1px;
        animation: logoAnimate 6s ease-in-out infinite;
        animation-delay: 2s;
      }
      path:nth-child(3) {
        stroke: $primary;
        fill: $primary;
        stroke-width: 1px;
        animation: logoAnimate 6s ease-in-out infinite;
        animation-delay: 4s;
      }

      @keyframes logoAnimate {
        0% {
          stroke: $primary;
          fill: $primary;
        }
        50% {
          stroke: #1ad697;
          fill: #1ad697;
        }
        100% {
          stroke: $primary;
          fill: $primary;
        }
      }
    }
  }
  .name {
    font-size: rem(20);
    font-weight: 700;
    color: $black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
    display: block;
  }
  .descr {
    margin-left: rem(13);
    padding-left: rem(13);
    height: rem(38);
    line-height: rem(38);
    border-left: 1px solid $gray-2;
    font-size: rem(14);
  }
}

.footerNav {
  li {
    margin: rem(5) rem(14);
  }
}
