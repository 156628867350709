@import 'mixins.scss';

.ant-select {
  &:not(.min-width-auto) {
    min-width: 100px;
  }
  &-arrow {
    @include transition-slow;
    top: 12% !important;
    right: rem(5) !important;
    width: auto !important;
    height: auto !important;
    color: lighten($text, 20%) !important;
    margin-top: 0 !important;
  }
  &-open .ant-select-arrow {
    transform: rotate(180deg);
    margin-top: rem(-2);
  }
  &-sm .ant-select-arrow {
    top: 1% !important;
  }
  &-item-option-content {
    svg,
    .ant-avatar {
      margin-right: rem(8);
    }
  }
  .iconify {
    vertical-align: -0.325em !important;
  }
}
