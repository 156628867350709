@import 'mixins.scss';

.icon {
  min-width: 1em;
  &__contactType,
  &__letterDirection {
    color: $orange;
    &.company,
    &.incoming {
      color: #a1cae2;
    }
  }
  &__meanOfContact {
    margin-top: rem(2);
    margin-right: rem(5);
    min-width: 1em;
    color: rgba(0, 0, 0, 0.45);
    font-size: 1rem;
  }
  &__btn {
    font-size: 1rem !important;
    min-width: 1em;
    padding-inline: initial !important;
  }
}
