.ant-picker-panel-layout > div {
  display: flex;
  .ant-picker-panels {
    order: 2;
  }
  .ant-picker-footer {
    order: 1;
    width: auto !important;
    min-width: auto !important;
  }
}
